@import '../../scss/base/variables';
// shared styling for all the forms in DASH

.form-heading-1 {
  padding-top: 30px;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.form-heading-2 {
  padding-top: 30px;
}

.form-heading-3 {
  margin: 15px 0;
}

.form-control {
  color: #4b4949 !important;
  font-family: $font-family-sans-serif;
}

.form-control::placeholder {
  color: $gray-darker !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-family: $font-family-sans-serif;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray-darker !important;
  font-size: 12px !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $gray-darker !important;
  font-size: 12px !important;
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $gray-darker !important;
  font-size: 12px !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: $gray-darker !important;
  font-size: 12px !important;
  opacity: 1;
}

.form-title {
  color: $brand-primary;
  font-weight: 600 !important;
}

$form-main-containeer-padding: 49px;
$form-footer-mt: 70px;

.form-main-container {
  padding-top: $form-main-containeer-padding;
  padding-bottom: $form-main-containeer-padding;
  z-index: 0;
}

.form-footer {
  margin-top: $form-footer-mt;
  margin-bottom: calc(#{$form-footer-mt} - #{$form-main-containeer-padding});
}

.open-modal-btn {
  color: $brand-primary;
  background-color: transparent;
  padding: 0;

  &:hover, &:focus{
    color: $brand-primary;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 992px) {
  .form-main-container {
    max-width: 992px;
  }
}
