@import '../../../scss/base/variables';

.tab-container {
  border-bottom: 1px solid #ddd;

  .tab {
    width: 200px;
    margin-right: 2px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    height: 40px;
    color: $brand-primary;
  }
  
  .tab:hover {
    background-color: #fff;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  
  .active-tab {
    color: $brand-primary;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #ddd;
    border-width: 1px 1px 2px 1px;
    border-bottom-color: transparent;
    margin-bottom: -2px;
  }

  .nav-tab {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color: $brand-primary;
    margin-bottom: -1px;
  }

  .nav-tab:hover {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-decoration: none;
  }

  .nav-tab-active {
    font-weight: 600;
    border: 1px solid #ddd;
    border-bottom-color: #fff;
  }
}