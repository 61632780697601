@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.btn-yellow{
  background-color: $brand-yellow;
  font-size: 13px;
  cursor: pointer;

  span{
    color: $gray-dark;

    > svg{
      height: 16px;
      vertical-align: sub;
      margin-right: 5px;
    }
  }

  &:hover, &:focus, &:active{
    color: $gray-dark;
    cursor: pointer;
    background-color: darken($brand-yellow, 7%);
  }

  &:disabled{
    background-color: $gray-lighter;
    opacity: 1;
    cursor: not-allowed;
    font-size: 13px;
    
    &:hover, &:focus, &:active{
      cursor: not-allowed;
    }

    span{
      color: $gray;
    }
  }
}