@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.btn-slider{
  background: $brand-white;
  height: 45px;
  width: auto;
  color: $gray-dark;
  padding: 15px 60px 12px 20px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  outline: none;
  position: relative;
  @include transition(0.3);

    .right-arrow-icon{
      margin-top: 0px;
      margin-left: 30px;
      position: absolute;
      z-index: 2;
      height: 10px;
      width: 10px;
      margin: 3px auto auto 35px;

    *{
      stroke: white;
    }
  }

  &:before{
    left: auto;
    right: 10px;
    z-index: 2;
    position: absolute;
    height: 100%;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
  }

  &:after{
    content: '';
    position: absolute;
    width: 50px;
    height: 200%;
    background: $brand-primary;
    z-index: 1;
    right: 0;
    top: 0;
    margin: -5px 0 0 -5px;
    @include transition(0.3);
    @include transo-2(80%, 60%);
    @include trans-rotate(20);
  }

  &:hover, &:focus{
    background-color: $gray-lighter;
    text-decoration: none;
  }

  &:hover:after{
    width: 60px;
  }
}
