@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

button{
  border-style: none;
  // background-color: none;
  cursor: pointer;
}

.btn{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  padding: 10px 12px;
  white-space: normal;
  text-align: left;
}

.btn-blue{
  background-color: $brand-primary;
  color: $body-bg;

  &:hover, &:focus, &:active{
  color: $brand-white;
  background-color: $brand-secondary;
  }

  span {
    color: $body-bg;
  }
}
