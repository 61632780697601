@import '../../scss/base/base';

// common css for all the alert types
.alert-container {
  display: flex;
  font-size: 15px;
  margin: 25px 0;
}

.alert-icon {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0; // workaround to get flexbox to work in ie
  flex-basis: auto; // workaround to get flexbox to work in ie
  padding: 0 15px;
}

.alert-text {
  width: 100%;
  padding: 15px 15px 15px 25px;

  >h3 {
    margin: 0;
  }

  >div>ul, ul {
    padding-inline-start: 20px;
    margin: 0;
  }

  >div>p {
    margin: 0;
  }
}

.alert-close-btn {
  background-color: transparent;
  height: fit-content;
  margin: 15px;
  padding: 0;
  display: inline-flex;
  flex-shrink: 0;
  flex-basis: auto;
}

// css for error alert message
.alert-error {
  border: 1px solid $state-danger-border;
  border-color: $state-danger-border;
  background-color: $state-danger-bg;
  color: $state-danger-text;

  .alert-error-icon {
    background-color: $state-danger-text;
  }
}

// css for warning alert message
.alert-warning {
  border: 1px solid $state-warning-border;
  border-color: $state-warning-border;
  background-color: $state-warning-bg;
  color: $state-warning-text;

  .alert-warning-icon {
    background-color: $state-warning-text;
  }
}

// css for successful alert message
.alert-success {
  border: 1px solid $state-success-border;
  border-color: $state-success-border;
  background-color: $state-success-bg;
  color: $state-success-text;

  .alert-success-icon {
    background-color: $state-success-text;
  }
}

// css for information alert message
.alert-info {
  border: 1px solid $state-info-border;
  border-color: $state-info-border;
  background-color: $state-info-bg;
  color: $state-info-text;

  .alert-info-icon {
    background-color: $state-info-text;
  }
}

