 
.react-datepicker-wrapper {
  display: block;
  z-index: 100;
}

.react-datepicker__input-container {
  display: block;
}

.react-datepicker__day {
  margin: 0;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  border-radius: 0;

  &:hover {
    border-radius: 0;
    background-color: #216ba5
  }
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0;
}

// don't display dates from the previous and next months in the calendar view
.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day--disabled {
  &:hover {
    background-color: transparent;
  }
}

.react-datepicker__month {
  margin: 0.8rem;
}

.mt_-20 {
  margin-top: -20px !important;
}