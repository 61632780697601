@import '../../../scss/base/variables';

.checkbox-dash {
  position: relative;
  display: inline;

  // styling for the input checkbox
  .form-check-input {
    width: 25px;
    height: 25px;
    margin: 4px 0 0 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  // when input checkbox is focused display outline around the custom checkbox
  .form-check-input[type=checkbox]:focus + label>div {
    outline: 5px auto -webkit-focus-ring-color;
  }

  // when input checkbox is selected display that the custom checkbox is also selected
  .form-check-input[type=checkbox]:checked + label>div:after {
    opacity: 1;
  }

  label {
    // styling for custom checkbox that's displayed on top of the default input checkbox
    div {
      cursor: pointer;
      position: absolute;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
      background: $brand-white;
      border: 1px solid $gray-light;
  
      &:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: $brand-primary;
        top: 4px;
        left: 4px;
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin: 5px 0 0 35px;
  
      &:hover {
        cursor: pointer;
      }
    }
  }

  input:disabled+label>div {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// displays a checkbox round
.round-checkbox {
	margin: auto;
	width: 25px;

  >input {
    margin: 0 !important;
    width: 23px !important;
		height: 23px !important;
  }

	label>div {
		border-radius: 20px;
		width: 23px !important;
		height: 23px !important;

		&:after {
			border-radius: 25px;
			width: 13px !important;
			height: 13px !important;
			box-shadow: 0 0 0 3px white, 0 0 0 6px $brand-primary;
		}
	}
}

