@import '../../scss/base/variables';
@import '../../scss/base/mixins';
@import '../../scss/base/base';

/* ------------------------ */
/*      Skip Navigation     */
/* ------------------------ */
.skip-content {
  text-align: center;
  position: fixed;
  top: 0;
  display: block;
  width: 100%;
  z-index: 1045;

  &:focus {
    outline: 0 !important;
    text-decoration: none;

    .content-tab {
      display: block;
      background-color: $brand-primary;
    }

    p {
      outline: 2px dashed $brand-yellow;
    }
  }

  .content-tab {
    margin: 0 auto;
    display: none;
    width: 50%;
    height: 20px;
    background-color: rgba(255,255,255,0.4);
    padding: 15px;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;

    p {
      color: $brand-white;
      font-size: $font-size-base;
      margin-top: -10px;
    }
  }
}

/* -------------------- */
/*  SocialMediaNavbar   */
/* -------------------- */
.social-media-nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $nichd-brand-lighter;
  z-index: 1040;
  @media (max-width : 680px) {
    display: none;
  }
}

.social-media-nav {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;

  a {
    color: #004085;
  }
}

.social-media-icon {
  display: inline-block;
  background-size: 20px 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  vertical-align: text-top;
  box-sizing: border-box;
  margin-right: 4px;
}

.pipe {
  &::after {
    content: '|';
    padding: 0 .4em;
    color: #326297;
    text-decoration: none !important;
  }
}

.social-divider {
  // don't display the pipe if screen width is larger than 880px
	@media (min-width: 880px) {
    margin-left: auto;
    &::after {
      content: '';
    }
	}
}

.fb {
  background-image: url('../../img/icons/navbar/social/fb.svg');
}

.twtr {
  background-image: url('../../img/icons/navbar/social/twitter.svg');
}

.pntrst {
  background-image: url('../../img/icons/navbar/social/pinterest.svg');
}

.yt {
  background-image: url('../../img/icons/navbar/social/youtube.svg');
}

.flckr {
  background-image: url('../../img/icons/navbar/social/flickr.svg');
}

.linkedin {
  background-image: url('../../img/icons/navbar/social/linkedin.svg');
}

.instagram {
  background-image: url('../../img/icons/navbar/social/instagram.svg');
}

/* ------------------------ */
/*     Upper Navigation     */
/* ------------------------ */
.navbar-upper-bkgd {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: $brand-white;
  background-image: url('../../img/icons/navbar/nichd_swirl_top.svg');
  top: 32px;

  @media (max-width: 875px) {
    top: 52px;
  }

  @media (max-width: 680px) {
    top: 0;
  }
}

.upper-nav {
  padding: 5px 0;
}

.nav-icon-link {
  >svg {
    height: 45px;
  }
  @media (max-width: 576px) {
    width: 50%;
    >svg {
      width: 100%;
    }
  }
};

.upper-login-btn {
  font-size: 16px;
  padding: 10px 20px;
  margin-right: 0;
  margin-left: auto;
  background-color: $brand-primary;
  color: $brand-white;

  &:hover, &:focus {
    cursor: pointer;
    text-decoration: none;
    background-color: darken($brand-primary, 7%);
    color: $brand-white;
  }

  @media (max-width : 680px) {
    display: none;
  }
}

.lower-login-btn {
  font-size: 16px;
  padding: 10px 20px;
  background-color: $nichd-brand-dark;
  color: $brand-white;
  border-radius: 0;
  display: none;

  &:hover, &:focus {
    text-decoration: none;
    background-color: $nichd-brand-darker;
    color: $brand-white;
  }

  @media (max-width : 680px) {
    display: block;
  }
}

.upper-account-navbar {
  margin-right: 0;
  margin-left: auto;

  @media (max-width : 1100px) {
    .account-nav-icon {
      margin: 0;
    }
    .account-nav-label {
      display: none;
    }
  }

  @media (max-width : 680px) {
    display: none;
  }
}

/* ------------------------ */
/*     Main Navigation      */
/* ------------------------ */
.dash-navbar-container {
  position: fixed;
  top: calc(32px + 55px);
  background-color: $nichd-brand-dark;
  z-index: 1040;
  @include box-shadow(0, 3px, 9px, 0, rgba(0,0,0,0.1));

  .navbar-collapse {
      max-height: 80vh;
      overflow-y: auto;
  }

  @media (max-width: 875px) {
    top: calc(52px + 55px);
  }

  @media (max-width: 680px) {
    top: 55px;
  }
}

.dash-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.dash-navbar-toggler { // collapsed button
  border: none;
  padding: .5rem;
  background-color: $nichd-brand-dark;
}

.dash-nav-link {
  padding: 10px;
  display: block;
  font-size: 16px;
  color: $brand-white;
  background-color: $nichd-brand-dark;
  width: 100%;
  text-align: left;
  line-height: 1.6;

  &:hover, &:focus, &:active {
    color: $brand-white;
    text-decoration: none;
    background-color: $nichd-brand-darker;
  }
}

.dash-nav-link.selected-path {
  background-color: $nichd-brand-darker;
}

.dash-nav-dropdown.show {
  >button {
    background-color: $nichd-brand-darker;
  }
}

.dash-nav-dropdown-menu {
  padding: 0;
  border-radius: 0;
  border: none;
  font-size: $font-size-medium;
  left: auto;
  border-top: 1px solid $gray-lighter;
  @include box-shadow(0, 3px, 9px, 0, rgba(0,0,0,0.3));

  .dropdown-item-parent {
    margin: 0;
    text-decoration: none;
    background-color: transparent;
    color: $gray;
    padding: 10px 20px;

    .parent-title {
      cursor: default;
    }

    .dropdown-item {
      padding: 10px 20px;
    }
  }

  .child-item {
    padding: 10px 35px;
  }

  a {
    margin: 0;
    padding: 10px 20px;
    text-decoration: none;
    color: $brand-primary;
    background-color: transparent;

    &:hover, &:focus {
      color: $brand-primary;
      background-color: $gray-lighter;
    }
  }

  @media (max-width: 1200px) {
    border-top: none;
    box-shadow: none;

    .dropdown-item-parent {
      color: white;
      background-color: $nichd-brand-dark;
    }

    a {
      color: white;
      background-color: $nichd-brand-dark;

      &:hover, &:focus {
        color: white;
        background-color: $nichd-brand-darker;
      }
    }
  }
}

/* ------------------------ */
/*  Account Navbar/Sidebar  */
/* ------------------------ */
.account-sidebar {
  position: fixed;
  top: 101px;
  height: 100%;
  width: 240px;
  right: 0;
  padding: 0;
  background-color: $brand-white;
  z-index: 1000;
  border-radius: 0;
  transition: .4s;
  box-shadow: -3px 0 4px rgba(0, 0, 0, .1);
  display: none;

  @media (max-width: 680px) {
    display: flex;
  }

  ul {
    width: 100%;
  }

  li {
    border-bottom: 1px solid $gray-lighter;
  }

  .account-nav-link {
    display: block;
    width: 100%;
    padding: 15px;
  }
}

.account-nav-link {
  font-size: 16px;
  color: $gray-base;
  padding: 13px 10px;

  &:hover, &:focus {
    text-decoration: none;
    color: $gray-base;
    background-color: $gray-lighter;
  }
}

.account-nav-icon {
  margin-right: 5px;
  * {
    stroke: $brand-primary;
  }
}

.my-cart-count {
  font-size: 0.83em;
  margin: 0 0 0 5px;
  padding: 1px 6px 2px 7px;
  border-radius: 10px;
  background-color: $brand-berry;
  color: $brand-white;
  font-weight: 500;
  letter-spacing: 0;
}