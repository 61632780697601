@import '../../../scss/base/variables';

.table{
  border: 1px solid #ddd;
}

.table th{
  border-bottom: 3px solid #ddd;
}

.table tbody th {
  border-bottom: 1px solid #dee2e6;
}

.table th, 
.table td{
  padding: 15px 20px;
}

.table.table-sm th,
.table.table-sm td{
  padding: 5px 20px;
}

thead {
  background-color: $brand-light-gray;
}

tbody th {
  font-weight: 400 !important;
}