@import '../../../scss/base/variables';

.form-control{
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 0;
  font-size: 13px;
}

textarea{
  line-height: $line-height-base !important;
}

.note{
  color: $gray;
  font-size: 12px;
}
