@import './scss/base/variables';
@import './scss/base/mixins';
@import './scss/base/base';

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: $font-size-base !important;
  overflow-x: hidden;
}

body, html {
  height: 100%;
  font-family: $font-family-sans-serif !important;
}

.anchor{
  display: block;
  height: 500px;
  margin-top: -500px;
  visibility: hidden;
}

h1, h2, h3, h4, h5, h6,
p, a, th, td, span {
  font-family: $font-family-sans-serif !important;
}

h1, h2 {
  line-height: 1.3 !important;
  font-weight: 500 !important;
}

h3{
  font-weight: 600 !important;
}
h4, h5, h6 {
  line-height: 1.3 !important;
  font-weight: 400 !important;
}

h1 { font-size: $font-size-h1 !important; }
h2 { font-size: $font-size-h2 !important; }
h3 { font-size: $font-size-h3 !important; }
h4 { font-size: $font-size-h4 !important; }
h5 { font-size: $font-size-h5 !important; }

p, th, td, label{
  font-size: 13px;
  line-height: $line-height-base;
}

a, span {
  font-size: inherit;
  line-height: $line-height-base;
}

b, strong, th {
  font-weight: 600 !important;
}

a:focus, button:focus {
  outline: 5px auto -webkit-focus-ring-color !important;
}

.serif{
  font-family: $font-family-serif;
  font-style: italic !important;
}

// ====== FORM =======
input[type="text"] {
  font-size: 13px;
  border: 1px solid rgb(204, 204, 204);
}

::-ms-clear{
  display: none; //Hide default IE close icon on forms
}

.form-control{
  border-radius: 0 !important;
}

@include placeholder {
  font-family: $font-family-serif;
  font-style: italic;
  font-size: 13px;
  font-weight: normal;
  color: #bdbdbd !important;
}

// ====== REACT TOOLTIP =======
.__react_component_tooltip.type-error {
  background: #9f2b29;
}

.__react_component_tooltip.type-info {
  background: #245290;
}
