@import '../../../scss/base/variables';

.btn.btn-clear{
  color: #045395;
  background-color: transparent;
  cursor: pointer;

  &.hover-effect {
    &:hover, &:focus{
      background-color: #f7f7f7;
    }
  }

  &.no-padding{
    padding: 5px 0;
  }

  span > svg{
    height: 16px;
    vertical-align: sub;
    margin-right: 5px;
  }

  &:disabled{
    cursor: not-allowed;

    &:hover, &:focus, &:active{
      cursor: not-allowed;
    }
  }
}
