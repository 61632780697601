@import '../../../scss/base/variables';

.dash-dropdown {
  width: fit-content;
  margin: 0 auto;

  .parent-item {
    color: #555555;
    &:hover {
      background-color: white;
      cursor: default;
    }
  }

  .child-item {
    margin-left: 15px;
  }

  >button {
    color: $gray-base;
    padding: 10px 20px;

    // renders down arrow
    &:after {
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
      content: "";
      border-top: 6px solid $gray-base;
      border-bottom: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
  }

  // renders up arrow
  >button[aria-expanded="true"]::after {
    border-top: 0;
    border-bottom: 6px solid $gray-base;
  }

  >ul {
    font-size: inherit;
    z-index: 1020;

    >li {
      padding: 10px 20px;
      font-size: inherit;
      margin: 0;

      &:hover, &:focus {
        cursor: pointer;
        background-color: $gray-lighter;
      }
    }
  }
}

.dash-actions-dropdown {
  // renders blue button with white text
  >button {
    background-color: $brand-primary;
    color: $brand-white;
    padding: 10px 20px;
  
    &:hover, &:focus, &:active {
      background-color: $brand-secondary;
    }

    // renders white down arrow
    &:after {
      border-top: 6px solid $brand-white;
    }
  }

  // renders white up arrow
  >button[aria-expanded="true"]::after {
    border-bottom: 6px solid $brand-white;
  }

  >ul>li {
    white-space: nowrap;
  }
}

.table-dropdown {
  display: table; // need this to center dropdown inside a table cell in IE and MS Edge
  >button {
    min-width: 105px;
  }
}