@import '../../../scss/base/variables';

.input-list-items {
  align-items: center;
  padding: 5px 0;
}

.input-list-display {
  overflow-wrap: break-word;
}

.list-group {
  list-style-type: none;
}