    
    #classic-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 2px;
    cursor: pointer;


    .classic-checkbox-input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        margin-left: -25px;
        z-index: 3;

    }

    // when input checkbox is focused display outline around the custom checkbox
    input:focus + label + span {
        outline: 2px solid #0084CA;
    }

    .facet-filter-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 11px;
        width: 11px;
        border: 1px solid black;
        border-radius: 3px;
        margin-top: 5px
    }

    // generic reusable css classes
    .bckg-gray-darker {
        background-color: lighten(#000 , 13.5%);
    }
}