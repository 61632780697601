@import '../../../scss/base/variables';

.form-control{
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 0;
  font-size: 13px;
}

input[type=text] {
  font-family: $font-family-sans-serif;
  display: inline-block;
  color: $gray-darker !important;
  font-size: 12px !important;
}
