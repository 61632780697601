@import '../../../scss/base/variables';

.dash-dropdown-search {
  position: relative;

  >ul {
    font-size: inherit;
    z-index: 1020;

    >li {
      padding: 10px 20px;
      font-size: inherit;
      margin: 0;

      &:hover, &:focus {
        cursor: pointer;
        background-color: $gray-lighter;
      }
    }
  }
}