@import '../../../scss/base/variables';
@import '../../../scss/base/variables';

.dropdown-opened-icon,
.dropdown-closed-icon {
  height: 15px;
  width: 15px;
}

.dash-collapse{
  color: $bright-blue;
  cursor: pointer;

  svg{
    float: left;
    margin-right: 5px;
    margin-top: 5px;

    *{
      stroke: $brand-primary;
    }
  }
}
