.error-label {
  color: rgb(213, 0, 0);
}

.useUserAsPI {
  text-align: left;
  padding-top: 10px;
}

.funding-info-error {
  text-align: center;
}

.research-team-error {
  text-align: center;
}

.institution-type-error {
  text-align: center;
  margin-top: 10px;
}