@import '../../../scss/base/variables';

.navbar-btn{
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 0;
  text-align: center;
  line-height: 1.42857;
  background-color: transparent;
  color: white;
  text-shadow: none;
  font-size: $font-size-base;
  letter-spacing: 1px;
  padding: 10px 20px;

  span {
    margin-top: 0px;
    margin: 0px;
    color: black;
    border-radius: 0px;
    background-color: transparent;
    font-size: $font-size-medium;
    font-weight: 500 !important;
    border: none;
    letter-spacing: 0.39px;
    text-shadow: none;
    padding: 0;
    outline: 0;
  }

  .caret-icon{
    svg {
      height: 10px;
      width: 10px;
      *{
        stroke-width: 6px;
      }
    }
  }
  svg {
    height: 15px;
    width: 15px;
    text-align: center;
    vertical-align: middle;
    margin: -3px 5px auto 5px;
    margin-top: -3px;

    * {
      color: $gray;
      stroke: $gray;
      stroke-width: 3px;
    }
  }
}

.navbar-btn.inverse {
  span {
    color: white;
    font-size: $font-size-base;
  }
  svg {
    height: 15px;
    width: 15px;
    text-align: center;
    vertical-align: middle;
    margin-top: -3px;

    * {
      color: $brand-yellow;
      stroke: $brand-yellow;
    }
  }
}
