.bkg-f1f1f1 {
  background: #f1f1f1;
}

.p-15 {
  padding: 15px;
}

.p-30 {
  padding: 30px;
}