@import '../scss/base/variables';
@import '../scss/base/mixins';

.body {
  font-family: $font-family-sans-serif !important;
  font-size: 13px !important;
}

.requiredField {
  color: #ad0a20;
}

.main-container {
  margin-top: 160px;
}

.dash-main {
  min-height: calc(100vh - 132px - 154px);
  margin-top: 132px;

  @media (max-width: 875px) {
    margin-top: 153px;
  }

  @media (max-width: 680px) {
    margin-top: 101px;
  }
}

.push-content {
  @include transition(0.5);
  overflow-x: hidden;
  right: 0;
  top: 0;
  left: 0;
  position: relative;
}

:disabled {
  cursor: not-allowed;

  &:hover, &:focus, &:active{
    cursor: not-allowed;
  }
}

.validation-error {
  border: 1px solid $validation-error !important;
  box-shadow: 0 0 5px $validation-error !important;
}

a {
  color: #0f4c8e;
}

// generic button styling
.dash-btn {
  text-align: center;
  padding: 10px 12px;
  font-size: 13px;
  display: block;
  cursor: pointer;

  &:hover,&:focus,&:active {
    text-decoration: none;
  }
}

.dash-btn:disabled {
  background-color: $gray-lighter;
  color: $gray;
  cursor: not-allowed;

  &:hover, &:focus, &:active {
    background-color: $gray-lighter;
    color: $gray;
    cursor: not-allowed;
    text-decoration: none;
  }
}

.yellow-btn {
  background-color: $brand-yellow;
  color: $gray-base;

  &:hover,&:focus,&:active {
    background-color: darken($brand-yellow, 7%);
    color: $gray-base;
  }
}

.green-btn {
  background-color: $brand-green;
  color: $brand-white;

  &:hover,&:focus,&:active {
    background-color: darken($brand-green, 5%);
    color: $brand-white;
  }
}

.blue-btn {
  background-color: $brand-primary;
  color: $brand-white;

  &:hover,&:focus,&:active {
    background-color: darken($brand-primary, 7%);
    color: $brand-white;
  }
}

.bright-blue-btn {
  background-color: $bright-blue;
  color: $brand-white;

  &:hover,&:focus,&:active {
    background-color: darken($bright-blue, 7%);
    color: $brand-white;
  }
}

.gray-btn {
  background-color: $gray-lighter;
  color: $gray-base;

  &:hover,&:focus,&:active {
    background-color: darken($gray-lighter, 7%);
    color: $gray-base;
  }
}

.dark-gray-btn {
  background-color: $gray;
  color: $brand-white;

  &:hover,&:focus,&:active {
    background-color: darken($gray, 7%);
    color: $brand-white;
  }
}

.transparent-btn {
  background-color: transparent;
  border: 1px solid $bright-blue;
  color: $brand-primary;

  &:hover,&:focus,&:active {
    background-color: darken($gray-lighter, 7%);
    color: $brand-primary;
  }
}

.nichd-brand-dark-btn {
  background-color: $nichd-brand-dark;
  color: $brand-white;

  &:hover,&:focus,&:active {
    background-color: darken($nichd-brand-dark, 7%);
    color: $brand-white;
  }
}

// use this class if you want to have a link to a specific section within a page
.page-tag::before {
  display: block;
  content: normal;
  margin-top: calc(-#{$upper-nav-height} - #{$lower-nav-height} - 5px);
  height: calc(#{$upper-nav-height} + #{$lower-nav-height} + 5px);
  visibility: hidden;
  pointer-events: none;
  z-index: -10;
}

// generic css classes that can be used to style any component
// keep the below css classes in alphabetical order for better organization
.c-validation_error {
  color: $validation-error;
}
.va-middle {
  vertical-align: middle;
}
.d-flex {
  display: flex;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.fl-left {
  float: left;
}

.fl-right {
 float: right;
}

.flexdir-col {
  flex-direction: column;
}

.flexwrap-wrap {
  flex-wrap: wrap;
}

.fs-14 {
  font-size: 14px;
}

.fs-30 {
  font-size: 30px;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-bold {
  font-weight: bold;
}

.fall-back {
  z-index: 0;
}

.move-forward {
  z-index: 100;
}

.h-100 {
  height: 100%;
}

.h-fit-content {
  height: fit-content;
}

.justifycontent-flex-end {
	justify-content: flex-end;
}

.justifycontent-space-between {
	justify-content: space-between;
}

.m-0 {
  margin: 0 !important;
}

.m-auto {
  margin: auto;
}

.m-50 {
  margin: 50px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-9 {
  margin-bottom: 9px;
}

.ml-0 {
  margin-left: 0;
}

.ml-50 {
  margin-left: 50px;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-auto {
  margin-right: auto;
}

.mr-auto {
  margin-right: 50px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1rem {
  margin-top: 1rem;
}

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-auto {
  margin-top: auto;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-auto {
  margin-bottom: auto;
}


.p-0 {
  padding: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.w-min-content {
  width: min-content;
}

.w-fit-content {
  width: fit-content;
}
