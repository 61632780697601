@import './variables';

/****************************************************************************************
Base sitewide SCSS
****************************************************************************************/
body {
	min-height: 100%;
	// font-family: open sans;
	font-size: $font-size-base;
	padding: 0;
}

body, html {
	height: 100%;
}

.padding-sides {
	padding: 0px 50px;
}

.anchor{
	display: block;
	height: 500px;
	margin-top: -500px;
	visibility: hidden;
}

a, button{
	&:focus{
		outline: 5px auto -webkit-focus-ring-color !important;
	}
}

#mainContent{
	#mainContainer{
		padding-bottom: 0px;
		min-height: calc(100vh + 224px);
	}
}

.content-wrapper{
	margin-top: 108px;
}

p, a, th, td{
	font-size: 1em;
}

span{
	font-size: inherit;
}

/****************************************************************************************
 Small Devices, Tablets
****************************************************************************************/
@media only screen and (min-width : 768px) {
	#mainContent{
		#mainContainer{
			min-height: calc(100vh + 291px);
		}
	}
}

/****************************************************************************************
 Medium Devices, Desktops
****************************************************************************************/
@media only screen and (min-width : 992px) {
	#mainContent{
		#mainContainer{
			min-height: calc(100vh + 286px);
		}
	}
}

@media only screen and (max-width : 768px) {
	.padding-sides{
		padding: 0px 15px;
	}
}