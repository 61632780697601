@import '../../../scss/base/variables';

.btn-blue{
  background-color: $brand-primary;
  color: $body-bg;

  &:hover, &:focus, &:active{
    color: $brand-white;
    background-color: darken($brand-primary, 10%);
  }

  span{
    font-size: $font-size-base;
  }

  span > svg{
    height: 16px;
    vertical-align: sub;
    margin-right: 5px;
  }
}