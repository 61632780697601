@import '../../../scss/base/variables';

select.dash-select{
  height: 38px;
  background-color: $brand-white;
  border: 0;
  width: 100%;
  outline: 1px solid #cccccc;
  outline-offset: -1px;
  font-family: $font-family-sans-serif;
  font-size: 12px;

  &::-ms-expand {
    display: none;
  }

  &:focus{
    outline: 1px solid #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
  }

  &[multiple]{
    height: 150px;
    padding: 10px 0;

    option{
      padding: 5px 10px;
    }
  }

  &:disabled {
    background-color: $gray-lighter;
    font-size: 12px !important;
    color: #4b4949 !important;
  }
}

.select-validation-error {
  outline: 1px solid $validation-error !important;
  box-shadow: 0 0 5px $validation-error !important;
}
