@import '../../scss/base/variables';
@import '../../scss/base/mixins';

.footer-top {
  background-color: $nichd-brand-light;
}

.footer-top-container {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 0;

  @media (max-width: 997px) {
    justify-content: center;
    flex-direction: column;
  }
}

.footer-logo {
  margin-right: 25px;
  @media (max-width: 997px) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
}

.footer-links {
  margin-top: auto;
  margin-bottom: auto;
  color: $brand-primary;
  text-align: center;

  a {
    color: #004085;
  }

  @media (min-width: 997px) {
    margin-left: auto;
  }
}

.footer-version {
  color: $gray-base;
}

.footer-bottom {
  background-color: $nichd-brand-dark;
  color: $brand-white;
  font-size: 16px;
  padding: 15px 0;
  text-align: center;
}
