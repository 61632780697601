// angle mixin
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

// slanted edges
.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}


@mixin transition($sec){
  -webkit-transition: all #{$sec}s ease;
  -moz-transition: all #{$sec}s ease;
  -o-transition: all #{$sec}s ease;
  -ms-transition: all #{$sec}s ease;
  transition: all #{$sec}s ease;
}

@mixin trans($skew){
  -webkit-transform: $skew;
    -moz-transform: $skew;
    -ms-transform: $skew;
    -o-transform: $skew;
    transform: $skew;
}

@mixin transo($skew){
	-webkit-transform-origin: $skew;
  	-moz-transform-origin: $skew;
  	-ms-transform-origin: $skew;
  	-o-transform-origin: $skew;
  	transform-origin: $skew;
}

@mixin transo-2($skew-x, $skew-y){
  -webkit-transform-origin: $skew-x $skew-y;
    -moz-transform-origin: $skew-x $skew-y;
    -ms-transform-origin: $skew-x $skew-y;
    -o-transform-origin: $skew-x $skew-y;
    transform-origin: $skew-x $skew-y;
}

@mixin trans-rotate($degree){
  -o-transform: rotate(#{$degree}deg);
  -ms-transform: rotate(#{$degree}deg);
  -moz-transform: rotate(#{$degree}deg);
  -webkit-transform: rotate(#{$degree}deg);
  transform: rotate(#{$degree}deg);
}

@mixin box-shadow($top, $left, $blur, $spread, $color) {
  -webkit-box-shadow:$top $left $blur $spread $color;
  -moz-box-shadow:$top $left $blur $spread $color;
  box-shadow:$top $left $blur $spread $color;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
