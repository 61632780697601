@import '../../../scss/base/variables';

.download-btn {
  margin: 0 auto;
  display: block;
  border: 1px solid $brand-primary;
  width: 230px;

  svg * {
    stroke: $brand-primary;
    stroke-width: 3px;
  }
}