@import '../../../scss/base/variables';

.radio-dash{
  width: 25px;
  position: relative;
  display: inline;

  .form-radio-input{
    width: 25px;
    height: 25px;
    margin: 4px 0 0 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  .form-radio-input[type=radio]:checked + label:after{
    opacity: 1;
  }

  .form-radio-input[type=radio]:focus + label{
    outline: 5px auto -webkit-focus-ring-color;
  }


  label{
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background: $brand-white;
    border: 1px solid $gray-light;

    &:after{
      opacity: 0;
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: $brand-primary;
      top: 4px;
      left: 4px;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    margin: 5px 0 0 35px;
  }
}