@import '../../../scss/base/variables';
@import '../../../scss/base/mixins';

.actions-button {
  padding: 5px 10px;
  background-color: $brand-yellow;
  font-size: 12px;
}

.actions-dropdown {
  .dropdown {
    .navbar-btn {
      span {
        font-size: 12px;
      }
    }
  }
}