@import '../../scss/base/variables';

.modal-content{
  border-radius: 0px;
}

.modal-header{
  padding-left: 30px;
  background-color: $brand-primary;
  border-radius: 0;
}

.modal-header span{
  color: white;
}

.modal-title{
  color: white;
}

.modal-body{
  padding: 30px;
  border-radius: 0;
  font-size: 18px;

  .bold{
    font-weight: 600 !important;
  }

  .center{
    text-align: center;
  }
}


.modal-footer{
  background-color: #eeeeee;
  border-radius: 0;
}

.btn.modal-btn{
  padding: 10px 25px;
}

.challenge-footer {
  justify-content: space-around;

  p {
    max-width: fit-content;
    text-align: center;
    font-style: italic;
  }
}

.btn.challenge-btn{
  padding: 10px 25px;
  width: max-content;
}

.btn.btn-secondary{
  margin-right: .25rem;
  color: #292b2c;
  background-color: $brand-white;
  border-color: $btn-default-border;

  &:hover, &:focus, &:active{
    background-color: darken($brand-white, 10%);
  }
}

.modal-btn-blue{
  background-color: $brand-primary;
  color: $body-bg;
  margin-left: .25rem;

  &:hover, &:focus, &:active{
    color: $brand-white;
    background-color: $brand-secondary;
  }

  span{
    font-size: $font-size-base;
  }

  span > svg{
    height: 16px;
    vertical-align: sub;
    margin-right: 5px;
  }
}

.constraints-modal-checkbox {
  label>span {
    font-size: 18px;
  }
}

.reuse-request-modal-dropdown{
  font-size: 16px;
}

.constraints-table{
  width: fit-content;
}

.download-dua-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal {
  height: 800px; 
  overflow: scroll;
}

.curator-deny-modal {
  >label {
    font-size: 16px
  }

  >textarea {
    font-size: 16px;
  }
}

.mg-bt-0 {
  margin-bottom: 0;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}
