@import "../../../scss/base/variables";

.upload-btn {
  font-size: $font-size-base;
  background-color: $brand-yellow;
  float: left;
  height: 39px;

  svg {
    margin-right: 5px;
    vertical-align: sub;
  }
}

.upload-text {
  font-size: $font-size-base;
  border: 1px solid #cccccc;
  height: 39px;
  display: flex;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}

@media only screen and (min-width: 992px) {
  .dropzone {
    width: fit-content;
  }

  .upload-text {
    width: 400px;
  }
}
