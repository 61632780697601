@import '../../../scss/base/variables';

button.btn.btn-form-nav {
  padding: 0px;
  color: $brand-primary;
  font-weight: 600;
  background-color: transparent;

  &:hover{
    cursor: pointer;
    color: $brand-primary;
  }

  .right-arrow-icon{
    float: right;
    padding-left: 5px;

    svg{
      *{
        stroke: $brand-primary;
      }
    }
  }

  .left-arrow-icon{
    padding-right: 5px;

    *{
      stroke: $brand-primary;
    }
  }

  &:disabled{
    &:hover, &:focus, &:active{
      cursor: not-allowed;
    }
  }
}